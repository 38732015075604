import { RemixBrowser } from '@remix-run/react'
import { startTransition, StrictMode } from 'react'
import { hydrateRoot } from 'react-dom/client'

import ConnectWalletModalProvider from './context/connect-wallet-modal'

import { initializeClientSentry } from '~/sentry/sentry.client'

if (window.env.NODE_ENV === 'production' && window.env.SENTRY_DSN) {
  initializeClientSentry()
}

startTransition(() => {
  hydrateRoot(
    document,
    <StrictMode>
      <ConnectWalletModalProvider>
        <RemixBrowser />
      </ConnectWalletModalProvider>
    </StrictMode>
  )
})
